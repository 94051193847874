.tabs-nav {
    justify-content: space-between;
    padding-bottom: 24px;
    .tab {
        &.active {
            text-decoration: underline;
            color: inherit;
        }
        p {
            @media (max-width: $tablet) {
                font-size: 14px;
            }
        }
    }
}
.tab-pane {
    &.kiosk-pane {
        .product-wrap {
            display: flex;
            justify-content: space-between;
        }
        input {
            width: auto;

            max-width: 150px;
            margin-left: auto;
            @media(max-width:1280px) {
                max-width:115px;
            }
        }
        input[type="number"] {
            text-align: right;
        }
    }
}

/* ======================================
Our sections
========================================= */
footer.section {
    background: #fff;
}
header {
    @media (max-width: $tablet) {
          overflow:hidden;
     }
}
.section,
.fp-tableCell,
.fp-scrollable {
    @media (max-width: $tablet) {
        height: auto !important;
        min-height:auto;
    }
}

.section {
    background: #989898;
     @media (max-width: $tablet) {
          overflow:hidden;
     }
     @media print {
        background:#fff!important;
     }
     &--sprachshatz {
         .content {
             margin-top:200px;
             @media(max-width:$tablet) {
                 margin-top:0;
             }
         }
     }
    .section--title {
        .columns:last-child {
            @media (max-width: $tablet) {
                padding-bottom: 0px !important;
            }
        }
    }

    &.bg-black,
    &.bg-black-white {
        .section--title {
            padding-top: 153px !important;
            @media (max-width: $tablet) {
                padding-top: 70px !important;
            }
        }
    }
    &--title {
        padding-top: 154px !important;
        margin-bottom: 42px;

        @media (max-width: $tablet) {
            padding-top: 70px !important;
            margin-bottom: 6px;
        }
         @media print {
            margin-bottom:0;
            margin-top:-60px;
            padding-top:0px!important;
         }
    }
    .wrapper-columns {
        .column:first-of-type {
            z-index: 1;
        }
    }
    
    .columns:last-child {
        margin-bottom: 0.75rem;
        @media (max-width: $tablet) {
            padding-bottom: 30px !important;
            margin-bottom: 0;
        }
    }
    .block-type-image {
        margin-bottom: 1.5rem;
        figure {
            width: 100%;
            padding-bottom: 70%;
            background-position: center !important;
            background-size: cover !important;
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: inherit;
                width: calc(100% + 12%);
                left: -6%;
                height: 100%;
                object-fit: cover !important;
            }
        }
    }
    &.bg-white {
        background: #fff !important;
    }
    &.bg-geraldine {
        background: $geraldine;
        h1,
        h2,
        h3,
        h4,
        h5,
        ul li,
        p {
            color: #fff;
            -webkit-text-stroke-color: #fff;
        }
        .content {
            border-color: #fff;
        }
        .button {
            border-color: #fff;
            background: $geraldine;
            -webkit-text-stroke-color: #fff;
            &.button-white {
                background: #fff!important;
                -webkit-text-stroke-color: #000!important;
                border-color: #000!important;
            }
        }
    }
    &.bg-black-white-font {
        background: #000;
        h1,
        h2,
        h3,
        h4,
        h5,
        ul li,
        p {
            color: #fff;
            -webkit-text-stroke-color: #fff;
        }
        .content {
            border-color: #fff;
        }
         .button {
            border-color: #fff;
            background: #000;
            -webkit-text-stroke-color: #fff;
        }
    }
    &.bg-black {
        background: #000;
        h1,
        h2,
        h3,
        h4,
        h5,
        ul li,
        p {
            color: $geraldine;
            -webkit-text-stroke-color: $geraldine;
        }
        .content {
            border-color: $geraldine;
        }
        .button-white {
            border-color: $geraldine;
            -webkit-text-stroke-color: #000;
            &:hover {
                -webkit-text-stroke-color: $geraldine;
            }
        }
        .button {
            border-color: $geraldine;
            -webkit-text-stroke-color: $geraldine;
        }
    }
    .cover {
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
    }
}
@media print {
  .noPrint {
      display:none;
  }
}

/* ======================================
Our Gallery specific styles
========================================= */
.section.gallery {
   padding:0!important;
   background:$geraldine;
   
   .columns:last-child,
   .block-type-image {
    margin-bottom: 0;
}
.columns.is-variable > .column {
@media(max-width:$tablet) {
    padding-top: 0;
    padding-bottom: 0;
}
}
.columns:last-child {
    margin-bottom: -0.75rem;
}
.mg-bottom  {
    padding-bottom:0!important;
}
}
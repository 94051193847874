/* ======================================
Our header
========================================= */
header {
    .ig {
        position: fixed;
        display: block;
        right: 2.5%;
        top: 21px;
        z-index: 999;
        width: 8vw;
        @media (max-width: $tablet) {
            top: 10px;
            max-width: 48px;
            width: auto;
        }
        @media print {
            display: none;
        }
        &-img {
            display: block;
            height: 111px;
            width: auto;
            margin-left:auto;
            @media (max-width: $tablet) {
                height: 48px;
                width: 48px;
                object-fit: contain;
            }
        }
    }
    .logo {
        position: fixed;
        left: 0;
        top: 21px;
        left: 2.5%;
        z-index: 99;
        width: 44vw;
        &.active {
            @media(min-width:$tablet) {
            width: 10vw;
            }
        }
        @media (max-width: $tablet) {
            width: 100%;
            top: 10px;
        }
        @media print {
            position: static;
        }
        &-img {
            height: 111px;
            width: auto;
            display: none;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            &.active {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }
        .logo-short,
        .logo-long {
            @media (max-width: $tablet) {
                height: 48px;
            }
            @media print {
                display: none !important;
            }
        }
        .logo-white {
            @media print {
                display: block !important;
                opacity: 1 !important;
                visibility: visible !important;
                max-width: 50%;
            }
        }
        @media (max-width: $tablet) {
            h1 {
                font-size: 34px;
            }
        }
        &:hover {
            border-color: #000;
        }
    }
}

/* ======================================
Our integration popup
========================================= */
form {
    input  {
        background: none;
        border: none;
        padding: 0;
        font-size: 30px;
        line-height: 37px;
        color: #000;
        &::placeholder {
            font-size: 30px;
            line-height: 37px;
        }
    }
}

.modal {
    width: 100%;
    z-index: 999;
    &-background {
        background: transparent !important;
    }
    .content-inner {
        padding: 24px;
        min-height: 480px;
    }
    .form select {
        -webkit-border-radius:0;
    }
    .modal-content {
        width: 95% !important;
        height: auto;
        max-height: none;
        overflow: hidden;
        @media (max-width: $tablet) {
            overflow: visible;
        }
        .container {
            max-width: 100% !important;
        }
        .form-title {
            display: block;
            &.hidden {
                display: none;
            }
        }
        .thankyou {
            max-width: 80%;
            display: none;
            a {
                color: inherit;
            }
            &.visible {
                display: block;
            }
        }
    }
    .content {
        background: #989898;
        margin: 0 26px;
        position: relative;
        @media (max-width: 1280px) {
            margin: 0;
            width: 100%;
        }
        .close-modal {
            position: absolute;
            right: 20px;
            font-size: 4.8vw;
            top: 20px;
            cursor: pointer;
            @media (max-width: $tablet) {
                font-size: 30px;
            }
        }
        select,
        input,
        input::placeholder,
        p {
            @media (max-width: $s-laptop) {
                font-size: 20px;
                line-height: 1.16;
            }
        }
    }
    &.bg-black {
        .content {
            background: #000;
            border-color: $geraldine;
            .tab.active,
            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                color: $geraldine;
                -webkit-text-stroke-color: $geraldine;
            }
            .content {
                border-color: $geraldine;
            }
            .button-white {
                border-color: $geraldine;
                -webkit-text-stroke-color: #000;
                &:hover {
                    -webkit-text-stroke-color: $geraldine;
                }
            }
            .button {
                border-color: $geraldine;
                -webkit-text-stroke-color: $geraldine;
            }
        }
    }
    &.bg-white {
        .content {
            background: #fff !important;
        }
    }
    &.bg-geraldine {
        .content {
            background: $geraldine;
            border-color: #fff;
            .tab.active,
            input,
            textarea,
            select,
            select::placeholder,
            input::placeholder,
            textarea::placeholder,
            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                color: #fff;
                -webkit-text-stroke-color: #fff;
            }
            .content {
                border-color: #fff;
            }
            .button {
                border-color: #fff;
                background: $geraldine;
                -webkit-text-stroke-color: #fff;
            }
        }
    }
    &.bg-black-white-font {
        .content {
            background: #000;
            border-color: #fff;
            .button,
            .tab.active,
            input,
            textarea,
            select,
            select::placeholder,
            input::placeholder,
            textarea::placeholder,
            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                color: #fff;
                -webkit-text-stroke-color: #fff;
            }
            .button {
                border-color: #fff;
            }
            .content {
                border-color: #fff;
            }
        }
    }
}

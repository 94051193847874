/* ======================================
Our menu
========================================= */
.site-nav {
    position: fixed;
    top: 214px;
    z-index: 99;
    left: 2.5%;
    width: calc(50% - 5%);
    transition: $transition;
    @media (max-width: $tablet) {
        z-index: 1;
        width: 95%;
        margin: 0 auto;
        position: static;
        top: 0;
        margin-top:71px;
    }
    &.fade-out {
        @media (min-width: $tablet) {
            opacity: 0;
            visibility: hidden;
        }
    }
    .menu {
        &--item {
            margin-bottom: 12px;
            background: transparent;
            width: 100%;
            text-align: left;
            justify-content: flex-start;
            -webkit-text-stroke-color: #000;
            border-color: #000;
            color: #000;

            &:hover {
                @media (min-width: $tablet) {
                a {
                    transform: scale(-1, 1);
                    text-align: right;
                }
                }
            }
            a {
                transition: $transition;
                display: inline-block;
                transition: scale 0.05s linear;
                color: inherit;
                width: 100%;
                @media(max-width:$tablet) {
                    font-size:34px;
                }
            }
            &.bg-white {
                background: #fff !important;
            }
            &.bg-geraldine {
                background: $geraldine;

                color: #fff;
                -webkit-text-stroke-color: #fff;
            }
            &.bg-black-white-font {
                background: #000;
                border-color: #fff;
                color: #fff;
                -webkit-text-stroke-color: #fff;
            }
            &.bg-black {
                background: #000;

                color: $geraldine;
                -webkit-text-stroke-color: $geraldine;
            }
        }
    }
}

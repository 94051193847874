/* ======================================
Our gallery component specific styles
========================================= */
.gallery-component {
    flex-flow: wrap;
    margin-bottom: 1.5rem;
    &--image {
        width: 50%;
        flex-basis: 50%;
        .image-holder {
            width: 100%;
            padding-bottom: 100%;
            background-position: center !important;
            background-size: cover !important;
        }
    }
    img {
        width: 100%;
        display: block;
    }
}

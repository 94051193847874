/* ======================================
Our content box
========================================= */
.content {
    border: $stroke solid #000;
    border-radius: $radius;
    @media (max-width: $laptop) {
        border: $stroke2px solid #000;
    }
    @media (max-width: $tablet) {
        border-radius: 18px;
    }
    @media print {
        border: none;
    }
    &--cta {
        margin-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
    }
    &--inner {
        padding: 24px;
        @media (max-width: $tablet) {
            padding: 16px;
        }
        @media print {
            padding: 0;
        }
        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    a {
        color: inherit;
    }
    ol,
    ul {
        list-style: initial;
        padding-left: 24px;
        margin: 20px 0;
        @media (max-width: $tablet) {
            padding-left: 16px;
        }
    }
    table {
        border-spacing: 0;
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        margin: 48px 0;
        @media (max-width: $tablet) {
            margin: 24px 0;
        }

        td,
        th {
            padding: 8px 0;
            p {
                padding-right: 8px;
            }
        }
    }
}

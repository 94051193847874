/* ======================================
Our site button design
========================================= */
.button {
    color: black;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: $stroke;
    -webkit-text-stroke-color: black;
    font-size: $fontTitle; // 97px
    line-height: 1;
    background: transparent;
    padding: 10px 22px;
    border: $stroke solid #000;
    border-radius: $radius;
    height: auto;
    outline: none !important;
    box-shadow: none !important;
    display: inline-block;
    transition: $transition;
    cursor:pointer;
    width:auto;
    @media (max-width: $laptop) {
        border: $stroke2px solid inherit;
        -webkit-text-stroke-width: $stroke2px;
    }
    @media (max-width: $tablet) {
        -webkit-text-stroke-width: 1px;
        font-size: 34px;
        line-height: 1;
        border: 1px solid;
        border-radius: 18px;
        width: min-content;
        span {
            font-size:34px;
        }
    }
    // safari only
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            font-size: 4.5vw;
        }
    }
    // end safari only
    span {
        display: inline-block;
        transition: 0.05s linear;
    }
    &:hover {
        @media(min-width:$tablet) {
            span {
                transform: scale(-1, 1);
            }
        }
    }
    &:hover {
        border-color: #000;
        color: inherit;
        opacity: 1;
    }
    &.button-geraldine {
        border-color: #fff;
        background: $geraldine;
        -webkit-text-stroke-color: #fff;
    }
    &.button-white {
        background: #fff;
        -webkit-text-stroke-color: #000;
    }
    &.black {
        background: #000;
    }
    &.geraldine {
        background: $geraldine;
        border-color: $geraldine;
    }
    &.button-small {
        text-transform: uppercase;
        font-size: 39px;
        background: $overlayWhite;
        -webkit-text-stroke-width: $stroke2px;
        border: 2px solid #fff;
        -webkit-text-stroke-color: #fff;
    }
}

.integration-button {
    margin-top: -6.25rem;
    display: flex;
    justify-content: flex-end;
    
    @media (max-width: $tablet) {
        width: auto;
        text-align: center;

        margin-bottom: 0 !important;
        margin-top: -30px;
        a {
            width: 100%;
            text-align: center;
        }
        .button {
            font-size: 34px;
                width: auto;
            margin-top: -0.7rem;
        }
    }
}
.button,
    .noPrint {
        @media print {
            display:none!important;
        }
    }
/* ======================================
Our Kiosk specific styling
========================================= */
.site-nav .menu--item.button-kiosk {
  border-color: #fff;
  background: $geraldine;
  -webkit-text-stroke-color: #fff;
  color: #fff;
  @media (min-width: $tablet) {
    position: fixed;
    right: 2.5%;
    bottom: 64px;
    width: auto;
    //animation: slide-up 1.2s forwards;
    // animation-delay:1s;
  }
} // in header.php
.kiosk-layout {
  padding-bottom: 5.5rem !important;
  @media (max-width: $tablet) {
    padding-bottom: 30px !important;
  }
}
@keyframes slide-up {
  0% {
    bottom: -200px;
  }
  70% {
    bottom: 72px;
  }
  to {
    bottom: 64px;
  }
}


/* ======================================
Core variables & vendors 
========================================= */
@font-face {
    font-family: "Selecta";
  src: url("fonts/SelectaVM-Regular.otf");
  src: url("fonts/SelectaVM-Regular.woff2") format("woff2"),
    url("fonts/SelectaVM-Regular.woff") format("woff")
}
@import './scss/vendors/bulma';
@import './scss/core/variables';
@import './scss/core/fonts';
@import './scss/core/buttons';

/* ======================================
Components
========================================= */
@import './scss/components/header';
@import './scss/components/footer';
@import './scss/components/menu';
@import './scss/components/content';
@import './scss/components/section';
@import './scss/components/gallery';
@import './scss/components/product';
@import './scss/components/modal';
@import './scss/components/form';
@import './scss/components/tabs';

/* ======================================
Layouts
========================================= */
@import './scss/layouts/kontakt';
@import './scss/layouts/gallery';
@import './scss/layouts/kiosk';
@import './scss/layouts/shop';
@import './scss/layouts/single';

body {
  background:$gray;
  font-family:'Selecta','montserrat';
   min-height: 1vw;
    @media print {
      background:none!important;
    }
  #pagepiling {
    @media(max-width:$tablet) {
    overflow:hidden;
    }
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
div::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
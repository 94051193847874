/* ======================================
Our form designs
========================================= */
.bg-black {
    .form {
        select,
        input {
            color: $geraldine;
            &::placeholder {
                color: $geraldine;
            }
        }
    }
}
form {
    flex-direction: column;
    display: flex;
    @media (max-width: $tablet) {
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .blank {
        position: absolute;
        left: -9999px;
    }
    .content {
        @media (max-width: $tablet) {
            margin: 0 !important;
            border: none;
        }
        &--inner {
            @media(min-width:$tablet) {
                max-height: 400px;
                overflow: scroll;
            }
            @media(max-width:$laptop) {
                max-height:280px;
            }
            @media (max-width: $tablet) {
                padding: 0;
                border: none;
                max-height: none;
            }
        }
    }
    input[type="number"] {
        -webkit-appearance: none;
    }
    select {
        margin-left: -2px;
        -webkit-appearance: none;
    }
    .form--columns {
        @media (max-width: $tablet) {
            flex-direction: column;
        }
    }
    select,
    input {
        background: none;
        border: none;
        padding: 0;
        font-size: 30px;
        line-height: 37px;
        color: #000;
        outline: none;
        box-shadow: none;
        width: 100%;
        font-family: "Selecta", "montserrat";
        @media (max-width: $tablet) {
            font-size: 20px;
        }
        &::placeholder {
            font-size: 30px;
            line-height: 37px;
            color: #000;
            @media (max-width: $tablet) {
                font-size: 20px;
            }
        }
    }
}

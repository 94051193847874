h1,
h2 {
    color: black;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: $stroke;
    -webkit-text-stroke-color: black;
    font-size: $fontTitle; // 97px;
    line-height: 1;
    min-height: 0vw;
    hyphens: auto;
     @media print {
        font-size:32px;
     }
    span {
        transform: scale(-1, 1); // mirror
        display: inline-block;
        margin-left: 7px;
        @media (max-width: $tablet) {
            margin-left: 2px;
        }
    }
    // safari only
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            font-size: 4.5vw;
        }
    }
    // end safari only
    @media (max-width: $laptop) {
        -webkit-text-stroke-width: $stroke2px;
    }
    @media only screen and (max-width: 900px),
        (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape),
        (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
        font-size: 4.5vw;
    }
    @media (max-width: $tablet) {
        -webkit-text-stroke-width: 1px;
        font-size: 36px;
        line-height: 1;
    }
}
h1,
h2 {
    margin-bottom: 24px;
    color: #000;
    hyphens: auto;
    @media (max-width: $tablet) {
        margin-bottom: 8px;
    }

}
h3 {
    font-size: 39px; // 39px;2.031vw
    line-height: 40px;
    margin-bottom: 24px;
    color: #000;
    hyphens: auto;
    @media(max-width:$s-laptop) {
        font-size:30px;
        line-height:31px;
    }
    @media (max-width: $tablet) {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 8px;
    }
     @media print {
        font-size:18px;
        line-height:1.2;
     }
}
ul li,
p {
    font-size: 30px; // 30px1.563vw
    line-height: 1.16;
    margin-bottom: 24px;
    color: #000;
    hyphens: auto;
     @media(max-width:$s-laptop) {
        font-size:20px;
    }
    @media (max-width: $tablet) {
        font-size: 15px;
        line-height: 14px;
    }
     @media print {
        font-size:16px;
        line-height:1.2;
        margin-bottom:8px;
     }
     strong {
        color:inherit;
     }
}
h1,h2, {
    @media print {
        color: black!important;
        -webkit-text-fill-color: transparent!important; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px!important;
        -webkit-text-stroke-color: black!important;
    }
}

h3,h4,h5, ul li, p, a {
     @media print {
        color:#000!important;
     }
}

/* ======================================
Our theme variables
========================================= */
$gray: #989898;
$geraldine: #FC7F83;
$oxley: #83A295;

$stroke: 2px;
$stroke2px: 1.5px;

$radius: 34px;

$transition: .15s linear;

$laptop: 1440px;
$s-laptop: 1300px;
$mobile: 560px; 

$overlayWhite: rgba(255,255,255,0.3);

$fontTitle: 4.8vw;
/* ======================================
Our bulma file
========================================= */
@charset "utf-8";
/* ======================================
   BULMA VARIABLES
========================================= */
// Update Bulma's global variables
$family-sans-serif: "AktivGrotesk", sans-serif;

// site specific containers
$gap: 16px;
$fullhd: 1196px + (2 * $gap);
$laptop: 1440px;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "./node_modules/bulma/sass/utilities/_all.sass";
@import "./node_modules/bulma/sass/utilities/_all.sass";
@import "./node_modules/bulma/sass/base/_all.sass";
@import "./node_modules/bulma/sass/grid/_all.sass";
@import "./node_modules/bulma/sass/grid/columns.sass";
@import "./node_modules/bulma/sass/elements/container.sass";
@import "./node_modules/bulma/sass/components/modal.sass";
@import "./node_modules/bulma/sass/components/modal.sass";
@import "./node_modules/bulma/sass/helpers/_all.sass";
@import "../node_modules/bulma-modal-fx/dist/css/modal-fx.min.css";

.columns.is-variable.is-custom {
   --columnGap: 2.5%;
   
}
.columns.is-variable>.column {
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.hide {
   display: none !important;
}
.is-hidden-mobile {
   @media (max-width: $tablet) {
      display: none;
   }
}
.mg-bottom {
   padding-bottom:5.5rem!important;
    @media(max-width:$tablet) {
       padding-bottom:0px!important;
    }
}
.is-hidden-desktop {
   @media (min-width: $tablet) {
      display: none;
   }
}
.container {
   max-width: 95% !important;
   width: 100% !important;
}

/* ======================================
Our product layout
========================================= */
.product {
    display: grid;
    grid-template-columns: calc(50% - 42.5px) calc(50% - 42.5px);
    column-gap: 85px;
    &--cta {
        display: flex;
        justify-content: flex-end;
    }
    .content {
        margin-bottom: 41px;
        overflow: hidden;
        &--inner {
            padding: 0;
            .image-holder {
                width: 100%;
                padding-bottom: 100%;
                background-position: center !important;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: $overlayWhite;
                }
            }
        }
    }
}
